
import Vue from "vue";
import ProjectNewReportDialog from "@/components/project/reports/ProjectNewReportDialog.vue";
import ProjectReportCard from "@/components/project/reports/ProjectReportCard.vue";
import ConfirmationDialog from "@/components/common/ConfirmationDialog.vue";
import {
  attachments,
  IAttachment,
  IProjectReport,
  IProjectReportsAddRequest,
  multiApiOperations,
  projects,
} from "@/api";
import { Actions } from "@/store/models";

export default Vue.extend({
  name: "ProjectReports",
  components: {
    ProjectNewReportDialog,
    ProjectReportCard,
    ConfirmationDialog,
  },
  props: {
    project: Object,
  },
  data() {
    return {
      newItemDialog: false,
      deletItemDialog: false,
      items: [] as IAttachment[],
      selected: new Set<number>(),
      enabledEdit: false,
    };
  },
  computed: {
    companyId: function (): number {
      return Number(this.$store.state.company.id);
    },
    isLocked: function (): boolean {
      return this.project.status === 0 || (this as any).isGssiAdmin;
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    // dialogs
    toggleNewItemDialog(dialog: boolean) {
      this.newItemDialog = dialog;
    },
    toggleDeletItemDialog(dialog: boolean) {
      this.deletItemDialog = dialog;
    },
    // reports
    select(id: number) {
      if (!this.selected.delete(id)) {
        this.selected.add(id);
      }
      this.enabledEdit = !!this.selected.size;
    },
    async getAll() {
      try {
        this.items = (await attachments.reports.getAll(this.project.id)).data;
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async add(item: IProjectReportsAddRequest) {
      try {
        const report = (await projects.reports.add(item)).data;
        this.$router.push(`/reports/${report.id}`);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async singleDownload(id: number) {
      try {
        await multiApiOperations(attachments.download, [id]);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async download() {
      try {
        await multiApiOperations(attachments.download, [...this.selected]);
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
    async remove() {
      if (!this.selected.size) {
        this.$store.dispatch(
          Actions.DisplayError,
          "Please select an item first."
        );
        return;
      }
      try {
        await multiApiOperations(attachments.delete, [...this.selected]);
        this.getAll();
        this.selected.clear();
      } catch (e) {
        this.$store.dispatch(Actions.DisplayError, e);
      }
    },
  },
});
